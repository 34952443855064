"use client";

import classnames from "classnames/bind";
import styles from "./ButtonPcs.module.css";
import { CosmosButton, CosmosButtonProps } from "@cosmos/web/react";

export type ButtonCustomColor = "white";

export interface ButtonPcsProps extends CosmosButtonProps {
  customColor?: ButtonCustomColor;
}

const cx = classnames.bind(styles);

export const ButtonPcs = ({
  className,
  customColor,
  ...props
}: ButtonPcsProps) => {
  return (
    <CosmosButton
      className={cx("button", className)}
      data-custom-color={customColor ? customColor : undefined}
      {...props}
    ></CosmosButton>
  );
};
