import { useThirdPartyScriptMikmak } from "../../helpers/useThirdPartyScriptMikmak";
import { CosmosButtonProps } from "@cosmos/web/react";
import { ButtonPcs, ButtonPcsProps } from "../ButtonPcs/ButtonPcs";

import classnames from "classnames/bind";
import { analyticsClick } from "../../helpers/analytics";

export interface BuyNowMikmakProps {
  mikmakIds: (string | null)[] | null;
  label: string;
  className: string;
  buttonSize: CosmosButtonProps["size"];
  buttonCustomColor?: ButtonPcsProps["customColor"];
  analyticsElementPosition?: string;
}

export const BuyNowMikmak = ({
  mikmakIds,
  label,
  className,
  buttonSize = "medium",
  buttonCustomColor,
  analyticsElementPosition,
}: BuyNowMikmakProps) => {
  const ThirdPartyScriptMikmak = useThirdPartyScriptMikmak();

  return (
    <>
      {ThirdPartyScriptMikmak}
      <ButtonPcs
        data-mm-wtbid="66d0c1b09ce37048eeaa27a4"
        data-mm-ids={mikmakIds?.join(",")}
        size={buttonSize}
        className={classnames(className)}
        customColor={buttonCustomColor ?? undefined}
        onClick={() => {
          analyticsClick({
            value: "Buy now",
            clickUrl: "???",
            elementPosition: analyticsElementPosition || null,
            method: "Outbound: FALSE",
            contentType: "CTA",
          });
        }}
      >
        {label ?? ""}
      </ButtonPcs>
    </>
  );
};
